import React, { useState, useEffect } from 'react';
import { Link, navigate } from 'gatsby';
import Layout from '../components/layout';
import Seo from '../components/seo';
import CustomFetch from '../components/fetch';
import CustomMessage from '../components/message';
import axios from 'axios';
import { saveAs } from 'file-saver';
import sal from 'sal.js';
import { Ticket, ChevronRight } from 'akar-icons';

const DocumentPage = () => {
    const [show, setShow] = useState('approved');
    const [documents, setDocuments] = useState({
        approved: [],
        pending: [],
    });
    const [messageSetting, setMessageSetting] = useState({
        showMessage: false,
        messageClass: 'dialog dialog--error',
        messageContent: '',
    });

    const newDocument = (e) => {
        if (e) {
            e.preventDefault();

            navigate('/new-document');
        }
    };

    const handleDownload = async (id, filename) => {
        const url = `${process.env.GATSBY_API_URL}file/download/${id}/null`;
        try {
            const token = localStorage.getItem('dpTicketToken'); // Retrieve the token from localStorage
            const config = {
                headers: {
                    Authorization: `Bearer ${token}`, // Include the token in the Authorization header
                },
                responseType: 'blob', // Correct placement of responseType
            };

            const response = await axios.post(url, {}, config);

            if (response.data && response.data instanceof Blob) {
                saveAs(response.data, filename); // You might want to use a dynamic file name based on content disposition header
            } else {
                setMessageSetting((prevState) => ({
                    ...prevState,
                    showMessage: true,
                    messageClass: 'dialog dialog--error',
                    messageContent: 'Invalid file format received.',
                }));
            }
        } catch (error) {
            setMessageSetting((prevState) => ({
                ...prevState,
                showMessage: true,
                messageClass: 'dialog dialog--error',
                messageContent:
                    'Failed to download file: ' +
                    (error.response?.data?.message || error.message),
            }));
        }
    };

    useEffect(() => {
        CustomFetch(
            'subcontractorDocuments/list',
            'POST',
            {},
            function (result) {
                result.forEach((a) => {
                    if (a.status === 0) {
                        setDocuments((prevState) => ({
                            ...prevState,
                            pending: [...prevState.pending, a],
                        }));
                    } else {
                        setDocuments((prevState) => ({
                            ...prevState,
                            approved: [...prevState.approved, a],
                        }));
                    }
                });
            },
            function (error) {
                setMessageSetting((prevState) => ({
                    ...prevState,
                    showMessage: true,
                    messageClass: 'dialog dialog--error',
                    messageContent: error,
                }));
            }
        );
    }, []);

    useEffect(() => {
        setTimeout(() => {
            sal();
        }, 100);
    }, [documents]);

    return (
        <Layout title="Documents">
            <Seo title="Documents" description="Dawn Projects Ticket System" />
            <CustomMessage data={messageSetting} />
            <div className="wrap">
                <div
                    className="dashmenu"
                    data-sal="slide-down"
                    data-sal-duration="500"
                    data-sal-easing="ease-in-out-quart"
                >
                    <button
                        className="dashmenu__item newticket"
                        onClick={newDocument}
                    >
                        New Document <Ticket size={20} />
                    </button>
                    <button
                        className="dashmenu__item"
                        onClick={() => {
                            setShow('approved');
                            setTimeout(() => {
                                sal();
                            }, 100);
                        }}
                    >
                        Approved Documents <span>{documents.length}</span>
                    </button>
                    <button
                        className="dashmenu__item"
                        onClick={() => {
                            setShow('pending');
                            setTimeout(() => {
                                sal();
                            }, 100);
                        }}
                    >
                        Pending <span>{documents.length}</span>
                    </button>
                </div>
                <div
                    className="breaktitle"
                    data-sal="slide-down"
                    data-sal-duration="500"
                    data-sal-easing="ease-in-out-quart"
                >
                    <h2>
                        {show === 'approved' ? 'Approved' : 'Pending'} Documents
                    </h2>
                </div>

                {show === 'approved' && (
                    <div className="tickets">
                        {documents.approved.length > 0 ? (
                            documents.approved.map((a) => (
                                <span
                                    style={{ cursor: 'pointer' }}
                                    className="ticket"
                                    onClick={() => {
                                        handleDownload(
                                            a.file?.id,
                                            a.file?.file_name
                                        );
                                    }}
                                    key={`document-${a.id}`}
                                >
                                    <span className="ticket__top">
                                        <h3>{a.label}</h3>
                                        <small>
                                            {
                                                a
                                                    .subcontractor_document_category
                                                    ?.label
                                            }{' '}
                                            <Ticket size={20} />
                                        </small>
                                        <p>{a.file?.file_name}</p>
                                    </span>
                                    <span className="ticket__bot">
                                        <em>Download Document</em>
                                        <ChevronRight size={24} />
                                    </span>
                                </span>
                            ))
                        ) : (
                            <div
                                className="noticket"
                                data-sal="slide-down"
                                data-sal-duration="500"
                                data-sal-easing="ease-in-out-quart"
                            >
                                <span>
                                    You currently have no approved documents
                                    uploaded to the systemn.
                                </span>
                            </div>
                        )}
                    </div>
                )}

                {show === 'pending' && (
                    <div className="tickets">
                        {documents.pending.length > 0 ? (
                            documents.pending.map((a) => (
                                <span
                                    style={{ cursor: 'pointer' }}
                                    className="ticket"
                                    onClick={() => {
                                        handleDownload(
                                            a.file?.id,
                                            a.file?.file_name
                                        );
                                    }}
                                    key={`document-${a.id}`}
                                >
                                    <span className="ticket__top">
                                        <h3>{a.label}</h3>
                                        <small>
                                            {
                                                a
                                                    .subcontractor_document_category
                                                    ?.label
                                            }{' '}
                                            <Ticket size={20} />
                                        </small>
                                        <p>{a.file?.file_name}</p>
                                    </span>
                                    <span className="ticket__bot">
                                        <em>Download Document</em>
                                        <ChevronRight size={24} />
                                    </span>
                                </span>
                            ))
                        ) : (
                            <div
                                className="noticket"
                                data-sal="slide-down"
                                data-sal-duration="500"
                                data-sal-easing="ease-in-out-quart"
                            >
                                <span>
                                    You currently have no pending documents
                                    uploaded to the systemn.
                                </span>
                            </div>
                        )}
                    </div>
                )}
            </div>
        </Layout>
    );
};

export default DocumentPage;
